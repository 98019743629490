import React from "react";
import styled from "styled-components"
import Youtube from '../../assets/svg/youtube.svg';
import Facebook from '../../assets/svg/facebook-logo.svg';
import PromotionLayout from "../../container/PromotionLayout"
import { Link as GatsbyLink } from "gatsby"

const LinkContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Link = styled(GatsbyLink)`
  width: 250px;
  margin-bottom: 30px;
  display: inline-flex;
  align-items: center;
  font-weight: bold;
  color: black;
  text-decoration: none;
`

const YoutubeLogo = styled(Youtube)`
  width: 40px;
  height: 40px;
  margin-right: 30px;
`

const FacebookLogo = styled(Facebook)`
  fill: white;
  width: 30px;
  height: 30px;
  margin-left: 5px;
`;
const FacebookWrapper = styled.div`
  background-color: #4267b2;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  margin-right: 30px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-decoration: none;
  font-size: 15px;
`;

const BlogWrapper = styled.div`
  background-color: #2DB400;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-decoration: none;
  font-size: 30px;
  font-family: '맑은 고딕', sans-serif;
  font-weight: bold;
  margin-right: 30px;
`;

const BlogLogo = styled.div`
  width: 30px;
  height: 30px;
  margin-left: 3px;
`;

function LinkPage () {
  return (
    <PromotionLayout title="관련 링크" heightNone={true}>
      <LinkContainer>
        <Link to="https://www.youtube.com/channel/UCTZnL0KIDfYYVKUJ08LZEPg">
          <YoutubeLogo />
          동아특수건설 유튜브
        </Link>
        <Link to="https://www.facebook.com/slope.kr">
          <FacebookWrapper><FacebookLogo /></FacebookWrapper>
          동아특수건설 페이스북
        </Link>
        <Link>
          <BlogWrapper><BlogLogo>b</BlogLogo></BlogWrapper>
          동아특수건설 블로그
        </Link>
      </LinkContainer>
    </PromotionLayout>
  )
}

export default LinkPage;